<template>
  <div class="container-main-content">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-3">
          Đăng nhập tới
          <span class="text-primary-color">{{ aliasMerchant }}</span>
        </h4>
        <p class="f14 mb-4 text-second-color">
          Điền thông tin đăng nhập để tiếp tục.
        </p>
        <div class="content-inner__body-form">
          <ValidationObserver ref="formLogin">
            <form @keyup.enter="handleLogin">
              <ValidationProvider
                name="Tên đăng nhập"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    v-model.trim="username"
                    class="form-control"
                    id="floatingUsername"
                    placeholder="name@example.com"
                  />
                  <label for="floatingUsername">Tên đăng nhập</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="Mật khẩu"
                vid="password"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="password"
                    v-model.trim="password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="name@example.com"
                  />
                  <label for="floatingPassword">Mật khẩu</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
          <b-form-checkbox
            id="checkbox-1"
            v-model="typeUser"
            name="checkbox-1"
            class="f14 mb-4"
            value="staff"
            unchecked-value="merchant"
          >
            Bạn là nhân viên doanh nghiệp
          </b-form-checkbox>
          <button
            type="button"
            class="btn btn-primary mb-4 w-100"
            @click="handleLogin"
          >
            ĐĂNG NHẬP
          </button>
        </div>
      </div>
      <Footer />
    </div>

    <ModalUserDeleted :showModal="showModal" @close="showModal = false" />

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ModalUserDeleted from "@/components/ModalUserDeleted.vue";
import { AuthService } from "../../services/auth.service";

export default {
  components: {
    Header,
    Footer,
    ModalUserDeleted,
  },
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      loading: {
        isLoading: false,
        fullPage: true,
      },
      aliasMerchant: this.$route.params.alias,
      username: "",
      password: "",
      typeUser: "merchant",
      showModal: false,
    };
  },
  methods: {
    handleLogin() {
      this.$refs.formLogin.validate().then(async (success) => {
        if (success) {
          try {
            this.loading.isLoading = true;
            if (this.typeUser === "merchant") {
              const response = await AuthService.login(
                this.username,
                this.password
              );
              if (response.code === "SUCCESS") {
                this.$router.push({
                  path: `/home/${this.aliasMerchant}${this.currentQueries}`,
                });
              } else if (response.data.code === "WRONG_USERNAME_OR_PASSWORD") {
                this.$refs.formLogin.setErrors({
                  password: "Tài khoản hoặc mật khẩu không đúng",
                });
              } else if (response.data.code === "MERCHANT_INACTIVE") {
                this.$router.push({
                  path: `/merchant-inactive${this.currentQueries}`,
                });
              } else if (response.data.code === "USER_INACTIVE") {
                this.$router.push({
                  path: `/user-inactive${this.currentQueries}`,
                });
              } else if (response.data.code === "USER_NEED_VERIFIED") {
                this.userId = response.data.data.userId;
                this.$router.push({
                  path: `/user-need-verify/${this.userId}${this.currentQueries}`,
                });
              } else if (response.data.code === "USER_DELETED") {
                this.showModal = true;
              } else {
                alert(response.data.code);
              }
            } else if (this.typeUser === "staff") {
              const response = await AuthService.login(
                this.username,
                this.password,
                this.aliasMerchant
              );
              if (response.code === "SUCCESS") {
                this.$router.push({
                  path: `/home/${this.aliasMerchant}${this.currentQueries}`,
                });
              } else if (response.data.code === "WRONG_USERNAME_OR_PASSWORD") {
                this.$refs.formLogin.setErrors({
                  password: "Tài khoản hoặc mật khẩu không đúng",
                });
              } else if (response.data.code === "MERCHANT_INACTIVE") {
                this.$router.push({
                  path: `/merchant-inactive${this.currentQueries}`,
                });
              } else if (response.data.code === "USER_INACTIVE") {
                this.$router.push({
                  path: `/user-inactive${this.currentQueries}`,
                });
              } else if (response.data.code === "USER_NEED_VERIFIED") {
                this.userId = response.data.data.userId;
                this.$router.push({
                  path: `/user-need-verify/${this.userId}${this.currentQueries}`,
                });
              } else if (response.data.code === "USER_DELETED") {
                this.showModal = true;
              } else {
                alert(response.data.code);
              }
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
